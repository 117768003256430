import React from 'react';

import { ReactComponent as RULSvg } from './rul.svg';

const RULLogo = ({ className }) => {
  return (
    <>
      <RULSvg className={className} />
    </>
  );
};

export default RULLogo;
