import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import loadable from '@loadable/component';

import * as styles from './PaperCanvas.module.scss';

const loadDynamicScript = (scriptId, url, returnObj) => {
  const existingScript = document.getElementById(scriptId);

  return new Promise((resolve) => {
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = url;
      script.id = scriptId;
      document.body.appendChild(script);

      script.onload = () => {
        resolve(returnObj());
      };
    } else {
      const check = () => {
        if (window.paper.PaperScope) {
          resolve(returnObj());
        } else {
          setTimeout(() => {
            check();
          }, 250);
        }
      };

      check();
    }
  });
};

const Paper = loadable.lib(
  () =>
    loadDynamicScript(
      'paper',
      'https://cdnjs.cloudflare.com/ajax/libs/paper.js/0.12.11/paper-core.min.js',
      () => ({
        paper: window.paper,
      })
    ),
  { ssr: false }
);
// const Paper = loadable.lib(
//   () =>
//     loadDynamicScript('paper', './paper-core.js', () => ({
//       paper: window.paper,
//     })),
//   { ssr: false },
// );

const PaperCanvas = ({
  children,
  onFrame,
  onLoad,
  onResize,
  onMouseMove,
  className,
  ...props
}) => {
  // const [ref, { width, height }] = useMeasure();
  const [ref, { width, height }] = useMeasure({ polyfill: ResizeObserver });
  const canvasRef = useRef(null);
  const paperRef = useRef(null);

  const onRun = useCallback(() => {
    paperRef.current.setup(canvasRef.current);

    onLoad(paperRef.current, canvasRef.current);

    if (onFrame) {
      paperRef.current.view.onFrame = (evt) => {
        onFrame(evt);
      };
    }

    if (onResize) {
      paperRef.current.view.onResize = (evt) => {
        onResize(evt);
      };
    }

    if (onMouseMove) {
      paperRef.current.view.onMouseMove = (evt) => {
        onMouseMove(evt);
      };
    }
  }, [onFrame, onLoad, onResize, onMouseMove]);

  const onPaperRef = (ref) => {
    paperRef.current = new ref.paper.PaperScope();

    if (paperRef.current && canvasRef.current) {
      onRun();
    }
  };

  const onRef = useCallback(
    (ref) => {
      if (!canvasRef.current) {
        canvasRef.current = ref;

        if (paperRef.current && canvasRef.current) {
          onRun();
        }
      }
    },
    [onRun]
  );

  useEffect(() => {
    if (!paperRef.current?.view?.viewSize) {
      return;
    }

    paperRef.current.view.viewSize.width = width;
    paperRef.current.view.viewSize.height = height;
  }, [width, height]);

  const cls = classNames(styles.wrapper, className);

  return (
    <Paper ref={onPaperRef}>
      {() => (
        <div className={cls} ref={ref} {...props}>
          {children(onRef, width, height)}
        </div>
      )}
    </Paper>
  );
};

PaperCanvas.propTypes = {};

export { PaperCanvas };
