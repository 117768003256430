import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CookieConsent from 'react-cookie-consent';
import RULLogo from '../../common/RULLogo/RULLogo';
import { PageSection } from '../../common/PageSection/PageSection';
import { IconButton } from '../../common/Icon/IconButton';
import { scrollCallback } from '../../../utils/interactions';
import { useRavensbourneContext } from '../../context/RavensbourneContext';

import * as styles from './Header.module.scss';

export const HeaderPad = () => <div className={styles.pad} />;

const Header = ({ siteTitle, topThreshold = 50, forceBackground = false }) => {
  const [isTop, setIsTop] = useState(true);
  const { setSearchOpen, setMenuOpen } = useRavensbourneContext();

  const cls = classNames(styles.header, {
    [styles.top]: isTop && !forceBackground,
  });

  useEffect(() => {
    return scrollCallback((pageY) => {
      setIsTop(pageY < topThreshold);
    });
  }, [topThreshold]);

  return (
    <>
      <header className={cls}>
        <PageSection seeThrough className={styles.container}>
          <h1 className={styles.title}>
            <Link to="/">
              <span className="visually-hidden">{siteTitle}</span>
              <RULLogo className={styles.logo} />
            </Link>
          </h1>
          <div className={styles.icons}>
            <IconButton
              className={styles.iconButton}
              icon="search"
              label="Search button"
              onClick={() => setSearchOpen(true)}
            />
            <IconButton
              className={styles.iconButton}
              icon="menu"
              label="Menu button"
              onClick={() => setMenuOpen(true)}
            />
          </div>
        </PageSection>
      </header>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        flipButtons
        acceptOnScroll
        acceptOnScrollPercentage={30}
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles
        buttonClasses={styles.cookieButton}
        declineButtonClasses={styles.cookieButton}
        containerClasses={styles.cookieContainer}
        contentClasses={styles.cookieContent}>
        <p>
          <strong>Your choice regarding cookies on this site:</strong> We use
          cookies to optimise site functionality and give you the best possible
          experience. By clicking <em>Accept</em>, or scrolling down the page
          you consent to the processing of your data by us and third parties.
        </p>
        <p>
          Read more about our cookie policy and how to disable them here:{' '}
          <a
            href="https://www.ravensbourne.ac.uk/privacy-and-cookies"
            target="_blank"
            rel="noreferrer">
            Privacy and Cookies
          </a>
        </p>
      </CookieConsent>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  topThreshold: PropTypes.number,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
