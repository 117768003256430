import React from "react";

import "./src/styles/variables/colors.css";
import "./src/styles/variables/general.css";
import "./src/styles/variables/sizes.css";
import "./src/styles/variables/type.css";
import "./src/styles/global.scss";

import { Layout } from "./src/components/layout/Layout";
import { SpecialCursorContainer } from "./src/components/common/SpecialCursor/SpecialCursor";
import { RavensbourneContextContainer } from "./src/components/context/RavensbourneContext";

export const wrapPageElement = ({ element, props }) => {
  // return <Layout location={props.location}>{element}</Layout>;
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <SpecialCursorContainer>
      <RavensbourneContextContainer>{element}</RavensbourneContextContainer>
    </SpecialCursorContainer>
  );
};

// export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
//   if (prevRouterProps && prevRouterProps.location.pathname === routerProps.location.pathname) {
//     return false;
//   }

//   return true;
// };

const transitionDelay = 200;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps,
  routerProps,
}) => {
  if (
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname
  ) {
    return false;
  }

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }

  //   return true;
  return false;
};

export const onServiceWorkerUpdateReady = () => {
  setTimeout(() => {
    window.dispatchEvent(new Event("sw-updated"));
  }, 1000);
};
