import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './PageSection.module.scss';

const PageSection = ({
  children,
  containerClassName,
  className,
  thin = false,
  dark = false,
  seeThrough = false,
  padY = false,
}) => {
  const cls = classNames(
    styles.section,
    { [styles.thin]: thin },
    { [styles.dark]: dark },
    { [styles.seeThrough]: seeThrough },
    { [styles.padY]: padY },
    containerClassName
  );

  const contentCls = classNames(styles.content, className);

  return (
    <div className={cls}>
      <div className={contentCls}>{children}</div>
    </div>
  );
};

PageSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  // Whether or not to trim it thinner
  thin: PropTypes.bool,

  // Black background, white text
  dark: PropTypes.bool,

  // Removes background
  seeThrough: PropTypes.bool,
};

export { PageSection };
